import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Coach } from '../types/coach.types';

@Injectable({
  providedIn: 'root',
})
export class CoachService {
  constructor(private http: HttpClient) {}

  /**
   * Returns searched coaches
   * @returns coaches
   */
  public searchCoaches(searchString: string) {
    return this.http.get<Coach[]>(environment.apiUrl + '/coach/search/' + searchString + '/');
  }

  public searchActiveCoaches(searchString: string) {
    return this.http.get<Coach[]>(environment.apiUrl + '/coaches/active/' + searchString + '/');
  }

  public getCoach(id: string, type: 'submission' | 'coach') {
    if (type === 'submission') {
      return this.http.post<Coach>(environment.apiUrl + '/coach/find/', { submission_id: id });
    }

    if (type === 'coach') {
      return this.http.get<Coach>(environment.apiUrl + '/coaches/' + id + '/');
    }
  }

  public getAllCoaches(params: HttpParams) {
    return this.http.get<Coach[]>(environment.apiUrl + '/coaches/', { params: params });
  }

  public updateCoach(coachId: string, updatedCoach: Partial<Coach>) {
    return this.http.patch<Coach>(environment.apiUrl + '/coaches/' + coachId + '/', updatedCoach);
  }
}
