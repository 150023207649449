import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Mentor } from '../types/mentor.types';
@Injectable({
  providedIn: 'root',
})
export class MentorService {
  constructor(private http: HttpClient) {}

  /**
   * Returns searched mentors
   * @returns mentors
   */
  public searchMentors(searchString: string) {
    return this.http.get<Mentor[]>(environment.apiUrl + '/mentor/search/' + searchString + '/');
  }

  public searchActiveMentors(searchString: string) {
    return this.http.get<Mentor[]>(environment.apiUrl + '/mentors/active/' + searchString + '/');
  }

  public getMentor(id: string, type: 'submission' | 'mentor') {
    if (type === 'submission') {
      return this.http.post<Mentor>(environment.apiUrl + '/mentor/find/', { submission_id: id });
    }

    if (type === 'mentor') {
      return this.http.get<Mentor>(environment.apiUrl + '/mentors/' + id + '/');
    }
  }

  public getMentorOfUser() {
    return this.http.get<Mentor>(environment.apiUrl + '/mentors/of_user/');
  }

  public getAllMentors(params: HttpParams) {
    return this.http.get<Mentor[]>(environment.apiUrl + '/mentors/', { params: params });
  }

  public updateMentor(mentorId: string, updatedMentor: Partial<Mentor>) {
    return this.http.patch<Mentor>(environment.apiUrl + '/mentors/' + mentorId + '/', updatedMentor);
  }
}
